// import React, { useEffect, useState } from "react";
// import logo from "./logo.svg";
// import "./App.css";

// function App() {
//   const [canInstall, setCanInstall] = useState(false);
//   useEffect(() => {
//     const deferredPrompt = localStorage.getItem("deferredPrompt");
//     setCanInstall(!!deferredPrompt);
//   }, []);

//   const handleInstallClick = () => {
//     const deferredPrompt = localStorage.getItem("deferredPrompt");
//     if (!deferredPrompt) return;

//     deferredPrompt.prompt();

//     deferredPrompt.userChoice.then((choiceResult) => {
//       if (choiceResult.outcome === "accepted") {
//         console.log("User accepted the install prompt");
//       } else {
//         console.log("User dismissed the install prompt");
//       }
//       localStorage.removeItem("deferredPrompt");
//     });
//   };

//   window.addEventListener("beforeinstallprompt", (e) => {
//     // Prevent Chrome 67 and earlier from automatically showing the prompt
//     e.preventDefault();

//     // Stash the event so it can be triggered later
//     localStorage.setItem("deferredPrompt", e);
//   });

//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//         <button onClick={handleInstallClick}>Install App</button>
//       </header>
//     </div>
//   );
// }

// export default App;
import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./Components/Home";
import Browse from "./Components/Browse";
import Footer from "./Components/Footer";
import Upcoming from "./Components/Upcoming";
import Subscribe from "./Components/Subscribe";
import MediaLanding from "./Components/Media/MediaLanding";
import Player from "./Components/Player/Player";
import Header from "./Components/Header";
import Login from "./Components/User/Login";
import Details from "./Components/Details";
import Support from "./Components/FooterPages/Support";
import CookiePolicy from "./Components/FooterPages/CookiePolicy";
import Privacy from "./Components/FooterPages/Privacy";
import TermsConditions from "./Components/FooterPages/Terms&Conditions";
import RefundPolicy from "./Components/FooterPages/RefundPolicy";
import AboutUs from "./Components/FooterPages/AboutUs";
import ContactUs from "./Components/FooterPages/ContactUs";
import Profile from "./Components/User/Profile";
import { useEffect, useState } from "react";
import AppSettingPage from "./Components/FooterPages/AppSettingPage";
import { UserProvider } from "./UserContext";
import { useCookies } from "react-cookie";
import instance from "./Components/common/Api";
import VideoJsPlayer from "./Components/Player/VideoJsPlayer";
import ShowMore from "./Components/HomeComponents/ShowMore";
import ForgotPassword from "./Components/User/ForgotPassword";
import { generateToken, messaging } from "./firebase";
import { onMessage } from "firebase/messaging";
import Location from "./Components/FooterPages/Location";
import VideoPlayerHLS from "./Components/Player/TempVideoPlayer";

const App = () => {
  const backgroundImageUrl = "https://www.bebu.app/assets/img/bg.svg";
  const [newUser, setNewUser] = useState(null);
  const [hideFooter, setHideFooter] = useState(false);
  const [userserIP, setUserIP] = useState(null);
  const [isMobile, setIsMobile] = useState(isMobileDevice());
  function isMobileDevice() {
    return window.matchMedia("(max-width: 1200px)").matches;
  }
  //https://www.bebu.app/logout-bg.png
  // const [showNotificationBanner, setShowNotificationBanner] = useState(
  //   Notification.permission === "default"
  // );
  const FetchUser = () => {
    const storedUserData = localStorage.getItem("BebuAppUser");
    const storedSocial = localStorage.getItem("SocialLogin");
    // ocalStorage.setItem("SocialLogin", true);
    return storedUserData ? JSON.parse(storedUserData) : null;
  };

  useEffect(() => {
    function handleResize() {
      setIsMobile(isMobileDevice());
    }
    async function fetchUserIP() {
      //UPDATE IP
      const ip = await window.getUserIP();
      if (ip === "Unknown") {
        setUserIP("45.115.53.96");
      } else {
        setUserIP("45.115.53.96");
      }
    }

    fetchUserIP();
    generateToken();
    onMessage(messaging, (payload) => {
      console.log(payload);
    });
    const setCookie = (name, value, days) => {
      const expires = new Date();
      expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
      document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
    };

    setNewUser(FetchUser);
    // const storedUserData = localStorage.getItem("userData");

    // if (storedUserData) {
    //   setUserData(JSON.parse(storedUserData));
    // }
    console.log("REFRESHHHH:::", newUser);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const location = useLocation();
  // const hideFooterRoutes = ["/play"];
  // const shouldHideFooter = hideFooterRoutes.includes(location.pathname);

  // const svgBackground = `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><circle cx='50' cy='50' r='40' fill='red'/></svg>")`;

  // const containerStyle = {
  //   backgroundImage: svgBackground,
  //   width: '200px', // Adjust width as needed
  //   height: '200px', // Adjust height as needed
  //   backgroundRepeat: 'no-repeat',
  //   backgroundSize: 'cover',
  //   backgroundPosition: 'center',
  // };

  return (
    <Router>
      <UserProvider>
        <div
          style={{
            width: "100%",
            minHeight: "55rem",
            position: "relative",
            backgroundImage: isMobile
              ? `url(./bebuAppBG.JPEG)`
              : `url(${backgroundImageUrl})`,
            backgroundSize: "cover",
            // backgroundPosition: "center",
            backgroundColor: "#141414",
            backgroundRepeat: "repeat-y",
            backgroundBlendMode: "overlay",
          }}
        >
          <Header userData={newUser} />

          <div>
            <Routes>
              <Route path="/" element={<Home ip={userserIP} />} />
              <Route
                path="/browse"
                element={
                  <Browse
                    userData={newUser}
                    ip={userserIP}
                    mobileDevice={isMobile}
                  />
                }
              />
              <Route
                path="/upcoming"
                element={
                  <Upcoming
                    userData={newUser}
                    ip={userserIP}
                    isMobile={isMobile}
                  />
                }
              />

              <Route
                path="/subscribe"
                element={<Subscribe userData={newUser} ip={userserIP} />}
              />

              <Route
                path="/medialanding"
                element={<MediaLanding userData={newUser} ip={userserIP} />}
              />
              <Route
                path="/play"
                element={
                  <Player
                    // toggleFooterVisibility={setHideFooter}
                    userData={newUser}
                    ip={userserIP}
                  />
                }
              />
              <Route path="/login" element={<Login ip={userserIP} />} />

              <Route
                path="/media/"
                element={<Details userData={newUser} ip={userserIP} />}
              />
              <Route
                path="/CookiePolicy"
                element={<CookiePolicy userData={newUser} ip={userserIP} />}
              />
              <Route
                path="/privacy"
                element={<Privacy userData={newUser} ip={userserIP} />}
              />
              <Route
                path="/terms-and-conditions"
                element={<TermsConditions userData={newUser} ip={userserIP} />}
              />
              <Route
                path="/support"
                element={<Support userData={newUser} ip={userserIP} />}
              />
              <Route
                path="/refund-policy"
                element={<RefundPolicy userData={newUser} ip={userserIP} />}
              />
              <Route
                path="/about-us"
                element={<AboutUs userData={newUser} ip={userserIP} />}
              />
              <Route
                path="/contact-us"
                element={<ContactUs userData={newUser} ip={userserIP} />}
              />

              <Route
                path="/media-video"
                element={<VideoJsPlayer userData={newUser} ip={userserIP} />}
              />

              <Route
                path="/profile"
                element={<Profile userData={newUser} ip={userserIP} />}
              />
              <Route
                path="/appsettings"
                element={<AppSettingPage userData={newUser} ip={userserIP} />}
              />
              <Route
                path="/showmore"
                element={<ShowMore userData={newUser} ip={userserIP} />}
              />

              <Route
                path="/forgot-password"
                element={<ForgotPassword userData={newUser} ip={userserIP} />}
              />
              <Route
                path="/location"
                element={<Location userData={newUser} ip={userserIP} />}
              />
              <Route
                path="/success"
                element={<Home userData={newUser} ip={userserIP} />}
              />
            </Routes>
          </div>

          {!hideFooter && <Footer ip={userserIP} />}
        </div>
      </UserProvider>
    </Router>
  );
};

export default App;

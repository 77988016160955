import React, { useEffect, useState } from "react";
import "./Home.css";
// import Carousel from 'react-bootstrap/Carousel';
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import LatestMovies from "./HomeComponents/LatestMovies";
import OriginalMovies from "./HomeComponents/OriginalMovies";
import ShortFilms from "./HomeComponents/ShortFilms";
import WebSeries from "./HomeComponents/WebSeries";
import HomeBanner from "./HomeComponents/HomeBanner";
import instance from "./common/Api";
import { Button, Spinner, Modal, ModalHeader } from "react-bootstrap";
import PWAInstallerPrompt from "react-pwa-installer-prompt";
import CookieConsent from "react-cookie-consent";
import { RiArrowRightSLine, RiDownload2Fill } from "react-icons/ri";
// import "react-cookie-consent/dist/cookie-consent.css";

const Home = (props) => {
  function isMobileDevice() {
    return window.matchMedia("(max-width: 1000px)").matches;
  }
  const [data, setData] = useState(null);
  const [bannerData, setBannerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(isMobileDevice());
  const [showModelForInstall, setshowModelForInstall] = useState(false);
  const [show, setShow] = useState(false);
  const [FCM_Sent, setFCM_Sent] = useState(true);
  const [newToken, setNewToken] = useState("");
  const handleClose = () => {
    setshowModelForInstall(false);
  };
  // const handleShow = () => setShow(true);

  // useEffect(() => {
  //   console.log(window.navigator.userAgent); // User agent string
  //   console.log(window.navigator.platform); // Operating system platform
  //   console.log(window.navigator.language); // Language
  //   // const tokenSent = localStorage.getItem("tokenSent");
  //   const storedUserFcmToken = localStorage.getItem("fcm");

  //   if (storedUserFcmToken !== null && storedUserFcmToken !== newToken) {
  //     setFCM_Sent(false);
  //     // localStorage.setItem("tokenSent", JSON.stringify(FCM_Sent));
  //     const stringWithoutQuotes = storedUserFcmToken.replace(/"/g, "");
  //     console.log(stringWithoutQuotes);
  //     // if (FCM_Sent) {
  //     //   setFCM_Sent(false);
  //     //   instance
  //     //     .post(
  //     //       `/saveUnauthUsersDeviceDetails?id=1&FCM_TOKEN=${storedUserFcmToken}`
  //     //     )
  //     //     .then((response) => {
  //     //       console.warn(response.data);
  //     //     })
  //     //     .catch((error) => {
  //     //       console.error("Error fetching data:", error);
  //     //     })
  //     //     .finally(() => {});
  //     // }
  //   }
  // }, []); // Run this effect only once on initial mount

  useEffect(() => {
    // const notificationInteracted = localStorage.getItem("pwaInteracted0");

    // If the notification hasn't been interacted with and 10 seconds have passed, show it
    const timeoutId = setTimeout(() => {
      setshowModelForInstall(true);
    }, 3000);

    // Clear the timeout when the component unmounts
    return () => clearTimeout(timeoutId);
  }, []); // Run this effect only once on initial mount

  useEffect(() => {
    instance
      .get(`/contentBycatAndPopular?ip=${props.ip}`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });

    function handleResize() {
      setIsMobile(isMobileDevice());
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    instance
      .get(`/home_sliders_web?ip=${props.ip}`)
      .then((response) => {
        setBannerData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false when fetch is complete
      });
  }, []);

  // const handleNotificationInteraction = () => {
  //   // Set a flag in local storage indicating that the notification has been interacted with
  //   localStorage.setItem("pwaInteracted0", "true");
  //   setshowModelForInstall(false);
  // };
  return (
    <>
      {loading && (
        <div className="loading-overlay">
          <Spinner animation="border" />
        </div>
      )}
      <div
        style={
          isMobile
            ? {
                width: "100%",
                minHeight: "55rem",
                // position: "relative",
                backgroundImage: `url(./bebuAppBG.JPEG)`,
                backgroundBlendMode: "overlay",
                backgroundSize: "contain",
                // backgroundPosition: "center",
                backgroundColor: "#141414",
                backgroundRepeat: "repeat-y",
              }
            : null
        }
      >
        <PWAInstallerPrompt
          render={({ onClick }) => (
            <Modal show={showModelForInstall} onHide={handleClose}>
              <ModalHeader
                style={{
                  padding: "0",
                  backgroundColor: "black",
                  borderBottom: "none",
                }}
              >
                <Button
                  variant="secondary"
                  style={{
                    color: "black",
                    backgroundColor: "white",
                    width: "30px",
                    height: "30px",
                    padding: "0px",
                    marginLeft: "auto",
                    marginRight: "10px",
                  }}
                  onClick={handleClose}
                >
                  x
                </Button>
              </ModalHeader>
              <Modal.Body
                style={{
                  backgroundImage: `url(./check-network.png)`,
                  backgroundSize: "cover",
                  color: "white",
                  textAlign: "center",
                  paddingTop: "20px",
                  paddingBottom: "20px",

                  // height: "15rem", // Adjust as needed
                  backgroundColor: "black",
                }}
              >
                <RiDownload2Fill
                  className="moving"
                  style={{ height: "50px", fontSize: "28px", color: "#e20788" }}
                />
                <h6>Install Bebu on your device!</h6>

                <Button
                  variant="secondary"
                  style={{
                    color: "white",
                    backgroundColor: "#58b7aa",
                  }}
                  onClick={onClick}
                >
                  Install
                </Button>
              </Modal.Body>
            </Modal>
          )}
          callback={(data) => console.log(data)}
        />

        {bannerData && (
          <HomeBanner
            banner={bannerData}
            style={{ backgroundColor: "#141414" }}
            isMobile={isMobile}
          />
        )}

        {data && data["0"] && (
          <LatestMovies
            data={data["0"].mediaContentByCategory.find(
              (category) => category.id === "Latest"
            )}
            isMobile={isMobile}
          />
        )}

        <div style={{ backgroundColor: "transparent" }}>
          {data && data["0"] && (
            <OriginalMovies
              data={data["0"].mediaContentByCategory.find(
                (category) => category.id === "Originals"
              )}
              isMobile={isMobile}
            />
          )}
        </div>
        {data && data["0"] && (
          <ShortFilms
            data={data["0"].mediaContentByCategory.find(
              (category) => category.id === "Short Film"
            )}
            isMobile={isMobile}
          />
        )}

        <div
          style={{
            // backgroundColor: "black",
            marginBottom: "0",
            paddingBottom: "1rem",
          }}
        >
          {data && data["0"] && (
            <WebSeries
              data={data["0"].mediaContentByCategory.find(
                (category) => category.id === "Web Series"
              )}
              isMobile={isMobile}
            />
          )}
        </div>
      </div>
      {isMobile ? (
        <CookieConsent
          location="bottom"
          buttonText="OK!"
          cookieName="myCookieConsent"
          style={{
            background: "rgb(88 183 170 / 90%)",
            zIndex: "1999",
            marginBottom: "50px",
            flexWrap: "none",
          }}
          buttonStyle={{
            background: "#e20788",
            color: "white",
            fontSize: "16px",
            borderRadius: "8px",
          }}
          expires={150}
        >
          This website uses cookies.
          <a
            href="/CookiePolicy"
            style={{ color: "black", textDecoration: "underline" }}
          >
            Learn More
          </a>
        </CookieConsent>
      ) : (
        <CookieConsent
          location="bottom"
          buttonText="OK!"
          cookieName="myCookieConsent"
          style={{
            background: "rgb(88 183 170 / 90%)",
            zIndex: "1999",
            bottom: "0px",
          }}
          buttonStyle={{
            background: "#e20788",
            color: "white",
            fontSize: "16px",
            borderRadius: "8px",
            width: "120px",
          }}
          expires={150}
        >
          This website uses cookies to ensure you get the best experience on our
          website.
          <a
            href="/CookiePolicy"
            style={{ color: "black", textDecoration: "underline" }}
          >
            Learn More
          </a>
        </CookieConsent>
      )}
    </>
  );
};

export default Home;

import React, { useRef } from "react";
import { useState } from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const WebSeries = ({ data, isMobile }) => {
  const listRef = useRef(null);
  const [hoveredCard, setHoveredCard] = useState(null);
  const navigate = useNavigate();
  const handleClick = (item) => {
    if (isMobile) {
      navigate("/play", { state: { someData: item } });
    } else {
      navigate("/media/", { state: { someData: item } });
    }
  };

  const handleMouseEnter = (index) => {
    setHoveredCard(index);
  };

  const handleMouseLeave = () => {
    setHoveredCard(null);
  };

  const scrollLeft = () => {
    if (listRef.current) {
      listRef.current.scrollBy({
        left: -200,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (listRef.current) {
      listRef.current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    }
  };

  const handleMoreClick = () => {
    console.log("clicked");
    navigate("/showmore", { state: { Data: data, title: "SHORT FILMS" } });
  };

  return (
    <div style={{ marginLeft: isMobile ? "" : "1.5rem" }}>
      <Row
        className="align-items-center"
        style={{ height: isMobile ? "35px" : "50px" }}
      >
        <Col xs={6}>
          {isMobile ? (
            <h4
              style={{
                color: "#e20788",
                marginBottom: "-15px",
                marginLeft: "12px",
              }}
            >
              WEB SERIES
            </h4>
          ) : (
            <h4
              style={{
                color: "#e20788",
                marginBottom: "-15px",
                marginLeft: "12px",
              }}
            >
              WEB SERIES
            </h4>
          )}
        </Col>
        <Col
          xs={6}
          className="d-flex justify-content-end"
          style={{ paddingRight: "20px" }}
        >
          {isMobile ? (
            <>
              <Button
                // variant="outline-light"
                style={{
                  backgroundColor: "transparent",
                  color: "rgb(29 181 165)",
                  marginBottom: "-5px",
                  marginRight: "0px",
                  borderWidth: "0px",
                }}
                onClick={handleMoreClick}
                // disabled // Disable the button
              >
                More
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="dark"
                className="iconbtns back-0 rounded-circle"
                onClick={scrollLeft}
                style={{
                  marginRight: "5px",
                  border: "3px solid #655e5ea1",
                  backgroundColor: "black",
                }}
              >
                &lt;
              </Button>
              <Button
                variant="dark"
                className="iconbtns back-0 rounded-circle"
                onClick={scrollRight}
                style={{
                  border: "3px solid #655e5ea1",
                  backgroundColor: "black",
                }}
              >
                &gt;
              </Button>
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={10} className="movie-list-container">
          <div ref={listRef} className="movie-list">
            {data.mediaContentList.map((item, index) => (
              <Card
                key={index}
                className={` ${isMobile ? "movie-item-mobile" : "movie-item"} ${
                  hoveredCard === index ? "zoomed-card" : ""
                }`}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                style={{
                  paddingBottom: "1rem",
                  backgroundColor: "transparent",
                }}
                onClick={() => handleClick(item)}
              >
                <Card.Img
                  variant="top"
                  src={item.mobile_image}
                  onError={(e) => {
                    e.target.src = "./bebu_default_image_hrz.png";
                  }}
                  style={{ height: "10rem" }}
                />
                <p className="text-white" style={{ fontSize: "13px" }}>
                  {item.title}
                </p>
              </Card>
            ))}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default WebSeries;

import { initializeApp } from "firebase/app";
import { getToken, getMessaging, onMessage } from "firebase/messaging";
import getBrowserFingerprint from "get-browser-fingerprint";
import instance from "./Components/common/Api";
import { useState } from "react";

const fingerprint = getBrowserFingerprint();
console.log(fingerprint);
// const [sentFcm, setSentFcm] = useState(true);

const firebaseConfig = {
  apiKey: "AIzaSyC6LJwOCz59yI_wZ1N3BGVUzJ7fzrkT4o4",
  authDomain: "demobebu.firebaseapp.com",
  projectId: "demobebu",
  storageBucket: "demobebu.appspot.com",
  messagingSenderId: "836912395201",
  appId: "1:836912395201:web:a68c71acd67c1941243540",
};

console.log("*** Firebase Config ***", firebaseConfig);

const firebaseApp = initializeApp(firebaseConfig);
// const messaging = getMessaging(firebaseApp);
export const messaging = getMessaging(firebaseApp);

export const generateToken = async () => {
  const permission = await Notification.requestPermission();
  console.warn(permission);
  if (permission === "granted") {
    const token = await getToken(messaging, {
      vapidKey:
        "BI5XkoqDAQniFBDKgc2ocE0Lar95D9uDC_bOPtJjarHJUN-iYPmU7PZ9P2G_S2M_gJYElUk47Y1iZlpz4DZmZFw",
    });
    console.log(token);
    localStorage.setItem("fcm", JSON.stringify(token));
  }
};

// export const updateNotification = async (token) => {
//   instance
//     .post(`/saveUnauthUsersDeviceDetails?id=1&FCM-TOKEN=${token}`)
//     .then((response) => {
//       console.warn(response.data);
//     })
//     .catch((error) => {
//       console.error("Error fetching data:", error);
//     })
//     .finally(() => {});
// };

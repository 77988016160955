import { Link, useLocation, useNavigate, withRouter } from "react-router-dom";
import React, { useRef, useState, useEffect } from "react";
import "./Player.css";
import screenfull from "screenfull";
import { Col, Row, Dropdown, Button, Card, Spinner } from "react-bootstrap";
import ReactPlayer from "react-player";
import { Slider, Direction } from "react-player-controls";
import {
  BsFillCaretRightFill,
  BsFillCaretLeftFill,
  BsBack,
  BsFillArrowLeftSquareFill,
  BsFillStarFill,
  BsFillCalendarFill,
  BsFillShareFill,
  BsFillPlayCircleFill,
  BsPlayFill,
} from "react-icons/bs"; // Import the BsFilter icon

// import axios from "axios";
// import Cookies from "js-cookie";
import instance from "../common/Api";
import { useCookies } from "react-cookie";
import VideoJsPlayer from "./VideoJsPlayer";

// import CommonListing from '../common/CommonListing';

const MobilePlayer = (props) => {
  //   const location = useLocation();
  const { someData, type, userData, tappedUrl, tappedPoster, allData } = props;

  function isMobileDevice() {
    return window.matchMedia("(max-width: 800px)").matches;
  }
  const [isVisible, setIsVisible] = useState(false);
  const [videoType, setVideoType] = useState(null);

  const [state, setState] = useState({
    url: null,
    pip: false,
    playing: false,
    controls: false,
    light: false,
    volume: 0.8,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false,
    volumeOpen: false,
    dropdownOpen: false,
    fullscreen: false,
  });
  const navigate = useNavigate();
  const [videoUrl, setVideoUrl] = useState("");
  const [posterUrl, setPosterUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [cookies, setCookie] = useCookies(["key1", "key2"]);
  const [isMobile, setIsMobile] = useState(isMobileDevice());
  const [mediaData, setMediaData] = useState(null);
  const [watchNext, setWatchNext] = useState(null);
  const [loadingNext, setLoadingNext] = useState(true);
  const [showContent, setShowContent] = useState(
    window.matchMedia("(max-width: 800px)").matches
  );
  // const [bebucookies, setBebuCookies] = useState(null);
  // const [cookies, setCookie] = useCookies();
  let timeout;
  const handleApiResponse = (response) => {
    // Assuming the response is an array of objects
    response.forEach((obj) => {
      const { key, value } = obj;
      // console.info(key);
      // console.info(value);
      setCookie(key, value); // Set the cookie with the key and value from the API response
    });
  };

  const [orientation, setOrientation] = useState(window.orientation);

  useEffect(() => {
    const handleOrientationChange = () => {
      setOrientation(window.orientation);
    };

    window.addEventListener("orientationchange", handleOrientationChange);
    console.warn(orientation);
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, [orientation]);

  useEffect(() => {
    setShowContent(window.matchMedia("(max-width: 800px)").matches);
    instance
      .get(
        `/videoViewBySlug?titleYearSlug=${someData.title}&videoid=${someData.id}`
      )
      .then((response) => {
        setMediaData(response.data);
        console.log("response.data", response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false when fetch is complete
      });

    console.log("SET URL");

    function handleResize() {
      setIsMobile(isMobileDevice());
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };

    let timeout;
    if (isVisible && state.playing) {
      timeout = setTimeout(() => {
        setIsVisible(false);
      }, 5000); // 5 seconds in milliseconds
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isVisible, showContent]);

  useEffect(() => {
    if (userData !== null) {
      console.warn(userData.id);
      instance
        .post(`/suggestions?id=${userData.id}&admin_video_id=${146}&skip=1`)
        .then((response) => {
          console.log(response.data.data);
          setWatchNext(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setLoadingNext(false);
        });
      if (watchNext) {
        console.log(watchNext);
      }
    } else {
      navigate("/login");
    }
  }, []);

  // const player = useRef(null);

  const handlePlayPause = () => {
    setState({ ...state, playing: !state.playing });
  };

  const [hovered, setHovered] = useState(false);
  // const history = useHistory();
  const handleMouseEnter = () => {
    if (state.playing) {
      setHovered(true);
    }
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleButtonClick = () => {
    // history.push('/play');
  };

  const containsTrailer = (str) => {
    return str.includes("Trailer");
  };

  const handleClickWatch = (item) => {
    // instance
    //   .get(
    //     `/videoViewBySlug?titleYearSlug=${item.title}&videoid=${item.id}`
    //   )
    //   .then((response) => {
    //     setMediaData(response.data);
    //     console.log("response.data", response.data);
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching data:", error);
    //   })
    //   .finally(() => {
    //     setLoading(false); // Set loading to false when fetch is complete
    //   });
  };

  const handleVideoChange = (newVideoUrl, newPoster) => {
    if (userData !== null) {
      console.warn(".....handleVideoChange.....", newVideoUrl, newPoster);
      setPosterUrl(newPoster);
      setVideoUrl(newVideoUrl);
    } else {
      navigate("/login");
    }

    // if (userData != null) {
    //   setVideoUrl(newVideoUrl);
    //   setPosterUrl(newPoster);
    // } else {
    //   navigate("/login");
    // }
  };

  // const skipBackward = (event) => {
  //   event.preventDefault();
  //   if (player.current) {
  //     const currentTime = player.current.getCurrentTime();
  //     const newTime = currentTime - 10;
  //     player.current.seekTo(newTime, "seconds");
  //     // setState({ ...state, playing: true });
  //   }
  // };

  // const skipForward = (event) => {
  //   event.preventDefault();
  //   if (player.current) {
  //     const currentTime = player.current.getCurrentTime();
  //     const newTime = currentTime + 10;
  //     player.current.seekTo(newTime, "seconds");
  //     // setState({ ...state, playing: true });
  //   }
  // };
  const formatGenresWeb = (genres) => {
    if (!genres || genres.length === 0) {
      return null;
    }

    return genres.join(" | ");
  };
  const getCastNames = (cast) => {
    return cast.map((item) => item.name).join(" | ");
  };

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const formatGenres = (genres) => {
    if (!genres || genres.length === 0) {
      return null;
    }
    return genres.join(", ");
  };

  return (
    <div>
      {mediaData && (
        <div
          className={`${
            orientation == 0
              ? "player-container-mobile"
              : "player-container-mobile"
          }`}
          onClick={handleClick}
        >
          {mediaData.video && (
            <div>
              <VideoJsPlayer
                // playerRef={player}
                src={videoUrl == "" ? mediaData.video : videoUrl} //`https://vz-b4f1e97e-483.b-cdn.net/65c65840-de66-4c27-afd0-a3b5a904b768/playlist.m3u8`} //videoUrl == "" ? mediaData.video : videoUrl} //`https://vz-b4f1e97e-483.b-cdn.net/65c65840-de66-4c27-afd0-a3b5a904b768/playlist.m3u8`} //videoUrl == "" ? mediaData.video : videoUrl} //"https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8"} //
                poster={posterUrl == null ? mediaData.mobile_image : posterUrl}
                className="react-player"
                isMobile={isMobile}
                handleVideoChange={handleVideoChange}
                userData={userData}
              />
            </div>
          )}
          {orientation == 0 && (
            <div
              className="dynamic-content"
              style={{ marginTop: orientation == 0 ? "47%" : "" }}
            >
              <Row>
                <Col className="col-md-6">
                  <h5
                    style={{
                      color: "white",
                      paddingLeft: "10px",
                      marginTop: mediaData.title.length > 20 ? "-10px" : "-2px",
                    }}
                  >
                    {mediaData && mediaData.title}
                  </h5>
                </Col>

                <Col
                  className="col-md-6"
                  style={{ marginTop: "-18px", marginRight: "0rem" }}
                >
                  <Button
                    style={{
                      backgroundColor: "#00000000",
                      borderWidth: "0",
                      color: "#1FB9A9",
                    }}
                  >
                    <BsFillShareFill />
                  </Button>{" "}
                  <Button
                    variant="info"
                    style={{
                      backgroundColor: "#1FB9A9",
                      color: "white",
                      width: "70%",
                      fontWeight: "700",
                    }}
                    onClick={() => handleVideoChange(mediaData.trailer_video)}
                  >
                    Watch Trailer
                    {/* {videoType === "main" ? "Watch Trailer" : "Play Episode"} */}
                  </Button>
                </Col>
              </Row>
              {/* <Row>
              <Col>
                <h1 style={{ color: "white", paddingLeft: "10px" }}>
                  {someData.title}
                </h1>
              </Col>
            </Row> */}
              <div style={{ display: "flex", alignItems: "center" }}>
                <h6
                  style={{
                    display: "inline-block",
                    textAlign: "center",
                    borderRadius: "15px",
                    marginLeft: "10px",
                    color: "white",
                    padding: "5px",
                    backgroundColor: "#e20788",
                    width: "50px",
                    marginRight: "2px", // Adjust margin as needed
                  }}
                >
                  U/A
                </h6>
                <h6
                  style={{
                    display: "inline-block",
                    marginLeft: "10px",
                    color: "white",
                  }}
                >
                  {mediaData && formatGenres(mediaData.geners1)}
                </h6>
              </div>
              <p
                style={{
                  color: "white",
                  fontSize: "12px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  textAlign: "justify",
                  marginBottom: "0.25rem",
                }}
              >
                {someData.description}
              </p>
              {/* <Col md={12} className="pb-1">
              <Button
                variant="primary"
                style={{
                  height: "40px",
                  width: "auto",
                  backgroundColor: "#58b7aa",
                  borderWidth: "0",
                  marginLeft: "10px",
                }}
                // onClick={() => setCurrentVideoUrl(mediaData.video)}
                onClick={handleClick}
              >
                <BsPlayFill
                  size={20}
                  className="mr-2"
                  style={{ marginBottom: "3px", marginRight: "5px" }}
                />
                Play
              </Button>
              <Button
                variant="primary"
                // onClick={handleShow}
                style={{
                  marginLeft: "10px",
                  height: "40px",
                  width: "auto",
                  backgroundColor: "#58b7aa",
                  borderWidth: "0",
                }}
                // onClick={() => setCurrentVideoUrl(mediaData.trailer_video)}
                // onClick={handleClickTrailer}
              >
                <BsFillPlayCircleFill
                  size={20}
                  className="mr-2"
                  style={{ marginBottom: "4px", marginRight: "5px" }}
                />
                Watch Trailer
              </Button>
            </Col> */}
              <h6
                style={{
                  color: "white",
                  padding: "10px",
                  marginBottom: "0px",
                  fontSize: "0.9rem",
                  textAlign: "justify",
                }}
              >
                Director:&nbsp; &nbsp; {mediaData && mediaData.director}
              </h6>
              <h6
                style={{
                  color: "white",
                  paddingLeft: "10px",
                  marginBottom: "0px",
                  fontSize: "0.9rem",
                  textAlign: "justify",
                }}
              >
                Cast:&nbsp; {mediaData && getCastNames(mediaData.cast)}
              </h6>

              {mediaData &&
              mediaData.seasons1 &&
              Array.isArray(mediaData.seasons1[1]) ? (
                <Button
                  variant="info"
                  style={{
                    marginLeft: "10px",
                    backgroundColor: "#1FB9A9",
                    color: "white",
                  }}
                >
                  Season 1
                </Button>
              ) : null}

              {mediaData &&
              mediaData.seasons2 &&
              Array.isArray(mediaData.seasons2[1]) ? (
                <Button
                  variant="info"
                  style={{
                    marginLeft: "10px",
                    backgroundColor: "#1FB9A9",
                    color: "white",
                  }}
                >
                  Season 1
                </Button>
              ) : null}

              {/* <CommonListing items={items} /> */}
              <div className="movie-list">
                {mediaData &&
                mediaData.seasons1 &&
                Array.isArray(mediaData.seasons1[1])
                  ? mediaData.seasons1[1].map((item, index) => (
                      <Card
                        key={index}
                        className="movie-item"
                        style={{
                          backgroundColor: "#14141400",
                          position: "relative",
                          // paddingBottom: "2rem",
                        }}
                        onClick={() =>
                          handleVideoChange(item.video, item.mobile_image)
                        }
                      >
                        <Card.Img variant="top" src={item.mobile_image} />
                        {/* <p
                        className="text-white"
                        style={{
                          position: "absolute",
                          bottom: "0px",
                          right: "10px",
                        }}
                      >
                        {item.title}
                      </p> */}
                      </Card>
                    ))
                  : null}
              </div>
              {userData != null ? (
                <>
                  <h4
                    style={{
                      color: "#e20788",
                      marginBottom: "0",
                      marginLeft: "10px",
                    }}
                  >
                    Watch Next
                  </h4>
                  <div className="movie-list-next">
                    {watchNext
                      ? watchNext.map((item, index) => (
                          <Card
                            key={index}
                            className="movie-item-next"
                            style={{
                              backgroundColor: "#14141400",
                              position: "relative",
                              paddingBottom: "5rem",
                            }}
                            onClick={() => handleClickWatch(item)}
                          >
                            <Card.Img
                              variant="top"
                              src={item.mobile_image}
                              style={{
                                objectFit: "cover",
                                width: "50%",
                                paddingRight: "0.7rem",
                                paddingBottom: "0.7rem",
                                borderRadius: "12px",
                              }}
                            />
                            {/* <p
                      className="text-white"
                      style={{
                        position: "absolute",
                        bottom: "0px",
                        right: "10px",
                      }}
                    >
                      {item.title}
                    </p> */}
                          </Card>
                        ))
                      : null}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          )}

          {/* <div className="dynamic-content-player">
            <Row>
              <Col className="col-md-6">
                <h5 style={{ color: "white", paddingLeft: "10px" }}>
                  {mediaData && mediaData.title}
                </h5>
              </Col>
              <Col className="col-md-6" style={{ marginLeft: "30px" }}>
                <Button
                  style={{
                    backgroundColor: "#00000000",
                    borderWidth: "0",
                    color: "#1FB9A9",
                  }}
                >
                  <BsFillShareFill />
                </Button>{" "}
                <Button
                  variant="info"
                  style={{ backgroundColor: "#1FB9A9" }}
                  onClick={() => handleVideoChange(mediaData.trailer_video)}
                >
                  Watch Trailer */}
          {/* {videoType === "main" ? "Watch Trailer" : "Play Episode"} */}
          {/* </Button>
              </Col>
            </Row>

            <h6 style={{ color: "#1FB9A9", paddingLeft: "10px" }}>
              {mediaData && mediaData.director}
            </h6>
            <p
              style={{
                color: "white",
                fontSize: "14px",
                padding: "10px",
                textAlign: "left",
              }}
            >
              {mediaData && mediaData.description}
            </p>
            <h6 style={{ color: "#1FB9A9", padding: "10px" }}>Info</h6>
            <h6 style={{ color: "white", padding: "10px" }}>
              Director: {mediaData && mediaData.director}
            </h6>
            <h6 style={{ color: "white", paddingLeft: "10px" }}>
              Cast: {getCastNames(mediaData && mediaData.cast)}
            </h6>
            <h6 style={{ color: "white", padding: "10px" }}>
              U/A | {formatGenresWeb(mediaData && mediaData.geners1)}
            </h6>
            {mediaData &&
            mediaData.seasons1 &&
            Array.isArray(mediaData.seasons1[1]) ? (
              <Button
                variant="info"
                style={{
                  marginLeft: "10px",
                  backgroundColor: "#1FB9A9",
                  color: "white",
                }}
              >
                Season 1
              </Button>
            ) : null}
            <div className="movie-list">
              {mediaData &&
              mediaData.seasons1 &&
              Array.isArray(mediaData.seasons1[1])
                ? mediaData.seasons1[1].map((item, index) => (
                    <Card
                      key={index}
                      className="movie-item"
                      style={{
                        backgroundColor: "#14141400",
                        position: "relative",
                        paddingBottom: "5rem",
                      }}
                      onClick={() =>
                        handleVideoChange(item.video, item.mobile_image)
                      }
                    >
                      <Card.Img variant="top" src={item.mobile_image} />
                      <p
                        className="text-white"
                        style={{
                          position: "absolute",
                          bottom: "0px",
                          right: "10px",
                        }}
                      ></p>
                    </Card>
                  ))
                : null}
            </div>
          </div> */}
        </div>
      )}
    </div>
  );
};

export default MobilePlayer;

import "./Cookies.css";
import React from "react";

const ContactUs = () => {
  return (
    <div className="container-fluid privacyPolicyImgbox">
      <div className="container privacyPolicyBellowTextBox">
        <div id="scroll">
          <div className="row">
            <div className="col-md-12 privacyContentBox">
              <h3
                style={{
                  color: "white",
                  paddingBottom: "10px",
                  paddingTop: "50px",
                }}
              >
                CONTACT US
              </h3>

              <h5
                style={{
                  color: "#FFFFFFCC",
                  paddingTop: "50px",
                  paddingBottom: "10px",
                }}
              >
                Mail us at{" "}
                <a
                  href="mailto:info@bebu.app"
                  style={{ color: "#e20788", textDecoration: "none" }}
                >
                  info@bebu.app
                </a>{" "}
                Or by contacting at the company address:
              </h5>
              <h6
                style={{
                  color: "#FFFFFFCC",
                  paddingTop: "20px",
                  paddingBottom: "10px",
                }}
              >
                Aniko Tech Media <br />
                PLOT NO.L 906/26, GIDC, <br />
                SECTOR 28, GANDHINAGAR,
                <br />
                PLOT NO.L 906/26, GIDC, <br />
                Gandhi Nagar <br />
                Gujarat 382028 INDIA
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;

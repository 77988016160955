import React, { useEffect, useState } from "react";
import "./Browse.css";
import {
  Col,
  Container,
  Modal,
  ModalHeader,
  Row,
  Spinner,
} from "react-bootstrap";
import { BsFillXOctagonFill, BsFunnelFill } from "react-icons/bs"; // Import the BsFilter icon
import { Form, Button } from "react-bootstrap";
import CustomInput from "./FooterPages/CustomInput";
import FilterPopup from "./PopUp/FilterPopup";
import instance from "./common/Api";
import { useNavigate } from "react-router-dom";
import { RiDownload2Fill } from "react-icons/ri";

const Browse = (props) => {
  const { userData } = props;
  const [containerHeight, setContainerHeight] = useState("auto");
  const [hoveredCard, setHoveredCard] = useState(null);
  // const [browseData, setBrowseData] = useState(null);
  const [browseData, setBrowseData] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedValue, setSelectedValue] = useState("Latest");
  const [filter, setFilter] = useState(false);
  const [filterModel, setFilterModel] = useState(false);
  const [originalBrowseData, setOriginalBrowseData] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  function isMobileDevice() {
    return window.matchMedia("(max-width: 1000px)").matches;
  }
  const [isMobile, setIsMobile] = useState(isMobileDevice());

  const handleClick = (item) => {
    if (props.mobileDevice) {
      if (userData != null) {
        navigate("/play", { state: { someData: item } });
      } else {
        navigate("/login");
      }
    } else {
      navigate("/media/", { state: { someData: item } });
    }
  };

  const payload = {
    filters: { az: false, latest: false },
    limit: 25,
    offset: 70,
  };

  const handleClose = () => {
    setFilterModel(false);
  };
  useEffect(() => {
    const payload = {
      filters: { az: false, latest: false },
      limit: 25,
      offset: 0, // Start with offset 0
    };

    function fetchData() {
      instance
        .post(`/browse_web_videos?ip=${props.ip}`, payload)
        .then((response) => {
          setBrowseData(response.data.content); //((prevData) => [...prevData, ...response.data.content]); // Append new data to existing data
          setOriginalBrowseData(response.data.content);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setLoading(false); // Set loading to false when fetch is complete
        });
    }

    fetchData(); // Initial fetch when component mounts

    // function handleScroll() {
    //   if (
    //     window.innerHeight + document.documentElement.scrollTop !==
    //       document.documentElement.offsetHeight ||
    //     loading // Don't fetch if already loading
    //   ) {
    //     return;
    //   }

    //   // Check if scrolled to the bottom
    //   if (
    //     window.innerHeight + document.documentElement.scrollTop ===
    //     document.documentElement.offsetHeight
    //   ) {
    //     console.error("NEW fetching data:");
    //     setLoading(true); // Set loading to true when fetching new data
    //     payload.offset += 25; // Increment offset by 25 (assuming each fetch fetches 25 items)
    //     fetchData(); // Fetch new data
    //   }
    // }
    function handleScroll() {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.offsetHeight;
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      console.log("Scrolling...");
      console.log("Window Height:", windowHeight);
      console.log("Document Height:", documentHeight);
      console.log("Scroll Top:", scrollTop);
      console.log("loading :", loading);

      if (!loading && windowHeight + scrollTop >= documentHeight) {
        console.log("Reached bottom of page. Fetching more data...");
        payload.offset += 25; // Increment offset by 25 (assuming each fetch fetches 25 items)
        fetchData(); // Fetch new data
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleMouseEnter = (index) => {
    setHoveredCard(index);
  };

  const handleMouseLeave = () => {
    setHoveredCard(null);
  };

  const sortBrowseDataAlphabetically = () => {
    setFilter(true);
    setSelectedValue("Albhabetic");
    setBrowseData((prevData) =>
      [...prevData].sort((a, b) => a.title.localeCompare(b.title))
    );
    setFilterModel(false);
  };

  useEffect(() => {
    const handleResize = () => {
      // Calculate the height based on the width to maintain a 16:9 aspect ratio
      const width =
        window.innerWidth > 0 ? window.innerWidth : window.screen.width;
      const height = (width * 9) / 16;
      setContainerHeight(`${height}px`);
    };

    // Call the handleResize function on initial load and whenever the window size changes
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSearchInputChange = (value) => {
    setSearchQuery(value);
  };

  const sortBrowseDataByPublishTime = () => {
    setSelectedValue("Latest");
    setFilter(true);
    setBrowseData((prevData) =>
      [...prevData].sort((a, b) => {
        const dateA = new Date(a.publish_time);
        const dateB = new Date(b.publish_time);
        return dateB - dateA; // Sort in descending order (latest to oldest)
      })
    );
    setFilterModel(false);
  };

  const revertToOriginalBrowseData = () => {
    setFilter(false);
    setBrowseData(originalBrowseData);
  };
  const handleShowDialog = () => {
    setShowDialog(true);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  const handleFilter = (value) => {
    setFilter(true);
    setSelectedValue(value);
    if (value == "Albhabetic") {
      sortBrowseDataAlphabetically();
    } else {
      sortBrowseDataByPublishTime();
    }
    // Do something with the selected value
    console.log("Selected value:", value);
  };

  return (
    <div
      style={
        isMobile
          ? {
              width: "100%",
              minHeight: "55rem",
              // position: "relative",
              backgroundImage: `url(./bebuAppBG.JPEG)`,
              backgroundBlendMode: "overlay",
              backgroundSize: "contain",
              // backgroundPosition: "center",
              backgroundColor: "#141414",
              backgroundRepeat: "repeat-y",
            }
          : null
      }
    >
      {loading && (
        <div className="loading-overlay">
          <Spinner animation="border" />
        </div>
      )}
      <Modal
        show={filterModel}
        onHide={handleClose}
        style={{ marginTop: "10rem" }}
      >
        <ModalHeader
          style={{
            padding: "0",
            backgroundColor: "black",
            borderBottom: "none",
          }}
        >
          <Button
            variant="secondary"
            style={{
              color: "black",
              backgroundColor: "white",
              width: "30px",
              height: "30px",
              padding: "0px",
              marginLeft: "auto",
              marginRight: "10px",
            }}
            onClick={handleClose}
          >
            x
          </Button>
        </ModalHeader>
        <Modal.Body
          style={{
            backgroundImage: `url(./check-network.png)`,
            backgroundSize: "cover",
            color: "white",
            textAlign: "center",
            paddingTop: "20px",
            paddingBottom: "20px",

            // height: "15rem", // Adjust as needed
            backgroundColor: "black",
          }}
        >
          <h2 style={{ paddingBottom: "1rem" }}>Apply Filter</h2>
          <Button
            variant="secondary"
            style={{
              color: "white",
              backgroundColor: "#58b7aa",
            }}
            onClick={() => sortBrowseDataAlphabetically()}
          >
            Albhabetic
          </Button>
          <br />
          <Button
            variant="secondary"
            style={{
              color: "white",
              backgroundColor: "#58b7aa",
            }}
            onClick={() => sortBrowseDataByPublishTime()}
          >
            Latest
          </Button>
        </Modal.Body>
      </Modal>
      <Container fluid>
        <Row className="d-flex justify-content-center text-center searchRow mb-2">
          <Col md={10} className="col-md-offset-2">
            <div className="search-wrapper mt-2">
              <Form.Group className="mat-form-field">
                <div className="position-relative">
                  {/* <Form.Control
                    type="text"
                    placeholder="Search Videos"
                    className="transparent-text-field"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  /> */}
                  <CustomInput
                    placeholder={"Search Video"}
                    // label={"Search Video"}
                    onValueChange={handleSearchInputChange} // Pass the callback function
                  />
                  {filter ? (
                    <Button
                      className="custom-button btn-round ml-2 position-absolute top-0 end-0"
                      style={{
                        fontSize: "14px",
                        fontWeight: "300",
                        width: "auto",
                        margin: "15px 60px",
                        background:
                          "linear-gradient(to bottom, #1fbaaa 0%, #263148 100%, #1fbaaa 100%)",
                      }}
                      onClick={revertToOriginalBrowseData}
                    >
                      {selectedValue}

                      <BsFillXOctagonFill
                        className="position-absolute top-0 end-0"
                        style={{}}
                      />
                    </Button>
                  ) : null}
                  <Button
                    variant="primary"
                    className="custom-button btn-round ml-2 position-absolute top-0 end-0"
                    style={{
                      margin: "15px 10px",
                      background:
                        "linear-gradient(to bottom, #1fbaaa 0%, #263148 100%, #1fbaaa 100%)",
                    }}
                    onClick={() => setFilterModel(true)}
                  >
                    <BsFunnelFill style={{ verticalAlign: "text-top" }} />
                  </Button>
                </div>
              </Form.Group>
            </div>
          </Col>
        </Row>
      </Container>
      <Container
        style={{ height: containerHeight * 100, paddingBottom: "auto" }}
      >
        <Row className="justify-content-center align-items-center pb-5">
          {browseData &&
            browseData
              .filter((item) =>
                item.title.toLowerCase().includes(searchQuery.toLowerCase())
              )
              .map((movie, index) => (
                <Col
                  xs={6}
                  sm={6}
                  md={4}
                  lg={3}
                  key={index}
                  className="image-item"
                >
                  <img
                    src={
                      isMobile
                        ? movie.browse_image
                        : movie.mobile_image || "/bebu_default_image.png"
                    }
                    onError={(e) => {
                      e.target.src = isMobile
                        ? "./bebu_default_image.png"
                        : "./bebu_default_image_hrz.png";
                    }}
                    alt={`Image ${index + 1}`}
                    className={`image ${
                      hoveredCard === index ? "zoomed-card" : ""
                    }`}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => handleClick(movie)}
                    style={
                      isMobile
                        ? {
                            objectFit: "cover",
                            // width: "8rem",
                            // height: "auto",
                            maxHeight: "100%",
                            aspectRatio: "9 / 16", // Maintain 16:9 aspect ratio
                            borderRadius: "8px",
                          }
                        : {}
                    }
                  />
                  <h2
                    className={`image-title ${
                      hoveredCard === index ? "zoomed-card" : ""
                    }`}
                    onMouseEnter={() => handleMouseEnter(index)} //browse_image
                    onMouseLeave={handleMouseLeave}
                  >
                    {movie.title}
                  </h2>
                </Col>
              ))}
        </Row>
      </Container>
    </div>
  );
};

export default Browse;

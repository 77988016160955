import "./Cookies.css";
import React from "react";

const AboutUs = () => {
  return (
    <div className="container-fluid privacyPolicyImgbox">
      <div className="container privacyPolicyBellowTextBox">
        <div id="scroll">
          <div className="row">
            <div className="col-md-12 privacyContentBox">
              <h3
                style={{
                  color: "white",
                  paddingBottom: "10px",
                  paddingTop: "50px",
                }}
              >
                ABOUT US
              </h3>
              <p>
                Weekend binges just got better! Bebu is the latest entertainment
                hub, built to get your heart racing. Watch the best of drama,
                comedy, romance and much more on the Bebu. Stream online on
                www.bebu.app We are certain you will love our original
                programming of Short Films and Web Series. The best part is you
                can try before you buy!
              </p>
              <div style={{ paddingLeft: "50px" }}>
                <p>• Content in local languages</p>
                <p>• Unlimited and Uninterrupted Video Streaming</p>
                <p>• Offline in-app Download available</p>
                <p>• Easy & flexible pricing</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;

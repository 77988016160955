import React from 'react';

const MediaLanding = () => {
    const backgroundImageUrl = "https://media1.bebu.app/images/videos/1638257242title.jpg";

  return (
    <div
    style={{
      position: 'relative',
      width: '100%',
      height: '100vh', // Adjust the height as needed
      backgroundImage: `url(${backgroundImageUrl})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}
  >
    {/* Dark layer with 0.2 opacity */}
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
      }}
    ></div>

    {/* Content of your page goes here */}
  </div>
    // <div className="main min-vh-custom">
    //   <app-media-landing className="ng-star-inserted">
    //     <div className="media-landing-mobile d-block d-md-none" id="topAnchor">
    //       <div className="container-fluid">
    //         <div className="col-md-12 pl-0 pr-0 mb-3">
    //           <img className="img-fluid rounded-lg" src="https://media1.bebu.app/images/videos/1676139537photoshoot%20150kb%20%20without%20logo%20main%20title%20hrz.jpg" alt="Media Landing"/>
    //         </div>
    //         <div className="col-md-12">
    //           <div className="movie-container">
    //             {/* Content within movie-container */}
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="media-landing d-none d-md-block" style={{backgroundImage: "url('https://media1.bebu.app/images/videos/1676139537photoshoot%20150kb%20%20without%20logo%20main%20title%20hrz.jpg')"}}>
    //       <div className="container-fluid" id="topAnchor">
    //         {/* Content within media-landing */}
    //       </div>
    //     </div>
    //   </app-media-landing>
    // </div>
  );
};

export default MediaLanding;
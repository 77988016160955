import React, { useEffect } from "react";
import "./Subscribe.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Image } from "react-bootstrap";
import ZoomImage from "./common/ImageHover";
import { useState } from "react";
import { useUser } from "../UserContext";
import instance from "./common/Api";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import axios from "axios";
import sha256 from "crypto-js/sha256";
import { Base64 } from "js-base64";

const Subscribe = (props) => {
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredGold, setIsHoveredGold] = useState(false);
  const [isHoveredSilver, setIsHoveredSilver] = useState(false);
  const [isHoveredBasic, setIsHoveredBasic] = useState(false);

  const [basicPlans, setBasicPlans] = useState(null);
  const [silverPlans, setSilverPlans] = useState(null);
  const [goldPlans, setGoldPlans] = useState(null);
  const [sixDaysPlans, setSixDaysPlans] = useState(null);

  const [activePlans, setActivePlans] = useState(null);

  const navigate = useNavigate();

  // const { userData } = useUser();
  const [plans, setPlans] = useState([]);
  // Function to filter plans based on title and set states
  const filterPlans = (plans) => {
    const gold = plans.content.filter(
      (plan) => plan.title.toLowerCase() === "gold"
    );
    const basic = plans.filter((plan) => plan.title.toLowerCase() === "basic");
    // Filter other plan types if needed

    setGoldPlans(gold);
    setBasicPlans(basic);
    // Set states for other plan types if needed
  };
  const formatDate = function (dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    return `${day}-${month}-${year}`;
  };

  const pushToLogin = () => {
    navigate("/login");
  };

  // async function sha256(str) {
  //   const buffer = new TextEncoder().encode(str);
  //   const hashBuffer = await crypto.subtle.digest("SHA-256", buffer);
  //   const hashArray = Array.from(new Uint8Array(hashBuffer));
  //   const hashHex = hashArray
  //     .map((byte) => byte.toString(16).padStart(2, "0"))
  //     .join("");
  //   return hashHex;
  // }

  const MakePhonePePay = async (payload) => {
    console.log("MakePhonePePay", payload.amount);
    //subscription_id
    // ip
    instance
      .post(
        `/user/phone_pe?amount=${payload.amount}&subscription_id=${payload.subscription_id}&id=${payload.id}&ip=${payload.ip}`
      )
      .then((response) => {
        console.log("response.data", response.data);
        console.log(
          "response",
          response.data.data.instrumentResponse.redirectInfo.url
        );
        window.location.href =
          response.data.data.instrumentResponse.redirectInfo.url;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        // window.location.href = "/";
        setLoading(false); // Set loading to false when fetch is complete
      });
  };

  const makePayment = (data) => {
    // console.log("makePayment", data);
    // console.log("data.listedPrice", data.listedPrice);
    // console.log("data.subscription_id", data.subscription_id);
    // console.log("props.ip", props.ip);
    // console.log("userData", props.userData.id);
    const payload = {
      amount: data.listedPrice,
      ip: props.ip,
      id: props.userData.id,
      subscription_id: data.subscription_id,
    };

    MakePhonePePay(payload);
    // https://testadmin.bebu.app/userApi/user/phone_pe?amount=1
    // const Merchant_ID = "M22J2CYM38VDM"; //"PGTESTPAYUAT"; // M22J2CYM38VDM
    // const Salt_Key = "575c1559-0e70-409c-b058-72b39978f367"; //"099eb0cd-02cf-4e2a-8aca-3e6c6aff0399"; //575c1559-0e70-409c-b058-72b39978f367
    // const transactionid = "T" + Date.now(); //"Tr-" + uuidv4().toString(36).slice(-6);
    // const payload = {
    //   merchantId: Merchant_ID,
    //   merchantTransactionId: transactionid,
    //   merchantUserId: "MUID" + Date.now(),
    //   amount: 100,
    //   redirectUrl: "https://testingdomain.bebu.app/", //`http://localhost:3000/api/status/${transactionid}`,
    //   redirectMode: "POST",
    //   // callbackUrl: "http://localhost:3000", //`http://localhost:3000/api/status/${transactionid}`,
    //   mobileNumber: "9999999999",
    //   paymentInstrument: {
    //     type: "PAY_PAGE",
    //   },
    // };
    // const dataPayload = JSON.stringify(payload);
    // console.log(dataPayload);
    // const dataBase64 = Base64.encode(dataPayload);
    // console.log(dataBase64);
    // const fullURL = dataBase64 + "/pg/v1/pay" + Salt_Key;
    // const dataSha256 = sha256(fullURL);
    // const checksum = dataSha256 + "###" + 1;
    // console.log("c====", checksum);
    // const UAT_PAY_API_URL = "https://api.phonepe.com/apis/hermes/pg/v1/pay"; //"https://api.phonepe.com/apis/hermes/pg/v1/pay"; //"https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay";
    // const response = await axios.post(
    //   UAT_PAY_API_URL,
    //   {
    //     request: dataBase64,
    //   },
    //   {
    //     headers: {
    //       accept: "application/json",
    //       "Content-Type": "application/json",
    //       "X-VERIFY": checksum,
    //     },
    //   }
    // );
    // const redirect = response.data.data.instrumentResponse.redirectInfo.url;
    // console.warn(response.data);
    // window.location.href = redirect;
    // router.push(redirect);
    // navigate(redirect);
  };

  // const makePayment = () => {
  //   const Merchant_ID = "PGTESTPAYUAT";
  //   const Salt_Key = "099eb0cd-02cf-4e2a-8aca-3e6c6aff0399";
  //   const data = {
  //     merchantId: Merchant_ID,
  //     merchantTransactionId: "T" + Date.now(),
  //     merchantUserId: "MUID" + Date.now(),
  //     name: "user",
  //     amount: 1,
  //     // redirectUrl: `http://localhost:3000/status/?id=${merchantTransactionId}`,
  //     // redirectMode: "POST",
  //     mobileNumber: "9999999999",
  //     paymentInstrument: {
  //       type: "PAY_PAGE",
  //     },
  //   };

  //   // sha256("your string").then((hash) => {
  //   //   console.log(hash);
  //   // });

  //   const payload = JSON.stringify(data);
  //   const payloadMain = Base64.encode(payload); //btoa(unescape(encodeURIComponent(payload))); //Buffer.from(payload).toString("base64");
  //   const keyIndex = 1;
  //   const string = payloadMain + "/pg/v1/pay" + Salt_Key;

  //   const sha256Data = sha256(string).then((hash) => {
  //     console.log(hash);
  //     return hash;
  //   }); //crypto.createHash("sha256").update(string).digest("hex");
  //   const checksum = sha256Data + "###" + keyIndex;

  //   // const prod_URL = "https://api.phonepe.com/apis/hermes/pg/v1/pay";
  //   const temp = "T" + Date.now();
  //   const prod_URL = `https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/status/${Merchant_ID}/${temp}`; //"https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay";

  //   const options = {
  //     method: "GET",
  //     url: prod_URL,
  //     headers: {
  //       accept: "application/json",
  //       "Content-Type": "application/json",
  //       "X-VERIFY": checksum,
  //     },
  //     data: {
  //       request: payloadMain,
  //     },
  //   };

  //   axios
  //     .request(options)
  //     .then(function (response) {
  //       console.log("PAYMENT RESPONSE", response.data);
  //     })
  //     .catch(function (error) {
  //       console.error(error);
  //     });
  // };

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    console.log("props...:", props);
    if (props.userData != null) {
      instance
        .post(`/subscription_plans_web?id=${props.userData.id}&ip=${props.ip}`)
        .then((response) => {
          setSubscriptionData(response.data);
          console.log("response.data", response.data);
          const active = response.data.active_plan;
          console.log(active[0], "<active");
          if (response.data && response.data.active_plan.length > 0) {
            setActivePlans(response.data.active_plan[0]);
          }
          console.log(response.data.active_plan);

          const goldPlans = response.data.content.filter(
            (plan) => plan.title.toLowerCase() === "gold"
          );
          const silverPlans = response.data.content.filter(
            (plan) => plan.title.toLowerCase() === "silver"
          );
          const basicPlans = response.data.content.filter(
            (plan) => plan.title.toLowerCase() === "basic"
          );
          const sixDaysPlans = response.data.content.filter(
            (plan) => plan.title.trim().toLowerCase() === "six days"
          );
          console.log("Basic Plans:", goldPlans[0]);
          setBasicPlans(basicPlans[0]);
          setSilverPlans(silverPlans[0]);
          setGoldPlans(goldPlans[0]);
          setSixDaysPlans(sixDaysPlans[0]);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setLoading(false); // Set loading to false when fetch is complete
        });
      console.log("User ID:", props.userData.id);
    } else {
      console.log("User ID not found");
      setLoading(false);
    }
    // filterPlans(subscriptionData);
    console.log("subscriptionData", props.userData);
  }, []);

  return (
    <>
      {props && props.userData != null ? (
        <>
          {activePlans != null ? (
            <>
              <Container style={{ padding: "20px", minHeight: "55rem" }}>
                {loading && (
                  <div className="loading-overlay">
                    <Spinner animation="border" />
                  </div>
                )}
                <Container>
                  <Row className="justify-content-center">
                    <Col xs={12} className="text-center text-white">
                      <h1>Your Subscribed Package</h1>
                    </Col>
                  </Row>
                </Container>
                <Row style={{ padding: "20px" }}>
                  <Col xs={12} md={2}></Col>
                  <Col
                    md={8}
                    className={`d-flex flex-column align-items-center mb-4 ${
                      isHoveredGold ? "zoomed-card" : ""
                    }`}
                  >
                    <Image
                      src="./subscrib.svg"
                      style={{
                        paddingBottom: "20px",
                        maxWidth: "100%",
                        height: "auto",
                      }}
                    />
                    <h1 className="month">
                      12
                      <br />
                      Months
                    </h1>
                    <h4 className="rupee">₹</h4>
                    <h1 className="offerPrice">
                      {activePlans.subscription_amount}
                    </h1>
                    <h1 className="planName">{activePlans.title}</h1>
                  </Col>
                  <Col md={2}></Col>
                </Row>
                <Row style={{ padding: "20px" }}>
                  <Col md={3}></Col>
                  <Col md={2}>
                    <div
                      style={{
                        backgroundColor: "#58b7aa",
                        color: "white",
                        textAlign: "center",
                        padding: "10px",
                        margin: "5px", // Added margin for spacing
                        borderRadius: "9px",
                        width: "auto",
                      }}
                    >
                      <h6>User</h6>
                      <h4>{props.userData.mobile}</h4>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div
                      style={{
                        backgroundColor: "#58b7aa",
                        color: "white",
                        textAlign: "center",
                        padding: "10px",
                        margin: "5px", // Added margin for spacing
                        borderRadius: "9px",
                      }}
                    >
                      <h6>Expiry Date</h6>
                      <h4>{formatDate(activePlans.expiry_date)}</h4>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div
                      style={{
                        backgroundColor: "#58b7aa",
                        color: "white",
                        textAlign: "center",
                        padding: "10px",
                        margin: "5px",
                        borderRadius: "9px",
                        width: "auto",
                      }}
                    >
                      <h6>Transaction Id</h6>
                      <h4>XXXX{activePlans.transaction_id}</h4>
                    </div>
                  </Col>
                  <Col md={3}></Col>
                </Row>
              </Container>
            </>
          ) : (
            <>
              <Container style={{ padding: "20px", minHeight: "55rem" }}>
                {loading && (
                  <div className="loading-overlay">
                    <Spinner animation="border" />
                  </div>
                )}
                <Container>
                  <Row className="justify-content-center">
                    <Col xs={12} className="text-center text-white">
                      <h1>Subscription Packages</h1>
                    </Col>
                  </Row>
                </Container>
                <Row style={{ padding: "20px" }}>
                  {goldPlans && (
                    <Col
                      xs={12}
                      md={6}
                      className={`d-flex flex-column align-items-center mb-4 ${
                        isHoveredGold ? "zoomed-card" : ""
                      }`}
                      onMouseEnter={() => setIsHoveredGold(true)}
                      onMouseLeave={() => setIsHoveredGold(false)}
                      onClick={() => makePayment(goldPlans)}
                    >
                      <Image
                        src="./subscrib.svg"
                        style={{
                          paddingBottom: "20px",
                          maxWidth: "100%",
                          height: "auto",
                        }}
                      />
                      <h1 className="month">
                        12
                        <br />
                        Months
                      </h1>
                      <h4 className="rupee">₹</h4>
                      <h1 className="offerPrice">{goldPlans.listedPrice}</h1>
                      <h1 className="planName">{goldPlans.title}</h1>
                      <h4 className="oldPrice">₹ {goldPlans.amount}</h4>
                    </Col>
                  )}
                  {silverPlans && (
                    <Col
                      xs={12}
                      md={6}
                      className={`d-flex flex-column align-items-center mb-4 ${
                        isHoveredSilver ? "zoomed-card" : ""
                      }`}
                      onMouseEnter={() => setIsHoveredSilver(true)}
                      onMouseLeave={() => setIsHoveredSilver(false)}
                      onClick={() => makePayment(silverPlans)}
                    >
                      <Image
                        src="./subscrib.svg"
                        style={{
                          paddingBottom: "20px",
                          maxWidth: "100%",
                          height: "auto",
                        }}
                      />
                      <h1 className="month">
                        3<br />
                        Months
                      </h1>
                      <h4 className="rupee">₹</h4>
                      <h1 className="offerPrice">{silverPlans.listedPrice}</h1>
                      <h1 className="planName">{silverPlans.title}</h1>
                      <h4 className="oldPrice">₹ {silverPlans.amount}</h4>
                    </Col>
                  )}
                </Row>
                <Row>
                  {basicPlans && (
                    <Col
                      xs={12}
                      md={6}
                      className={`d-flex flex-column align-items-center mb-4 ${
                        isHoveredBasic ? "zoomed-card" : ""
                      }`}
                      onMouseEnter={() => setIsHoveredBasic(true)}
                      onMouseLeave={() => setIsHoveredBasic(false)}
                      onClick={() => makePayment(basicPlans)}
                    >
                      <Image
                        src="./subscrib.svg"
                        style={{
                          paddingBottom: "20px",
                          maxWidth: "100%",
                          height: "auto",
                        }}
                      />
                      <h1 className="month">
                        1<br />
                        Month
                      </h1>
                      <h4 className="rupee">₹</h4>
                      <h1 className="offerPrice">{basicPlans.listedPrice}</h1>
                      <h1 className="planName">{basicPlans.title}</h1>
                      <h4 className="oldPrice">₹ {basicPlans.amount}</h4>
                    </Col>
                  )}
                  {sixDaysPlans && (
                    <Col
                      xs={12}
                      md={6}
                      className={`d-flex flex-column align-items-center mb-4 ${
                        isHovered ? "zoomed-card" : ""
                      }`}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                      onClick={() => makePayment(sixDaysPlans)}
                    >
                      <Image
                        src="./subscrib.svg"
                        style={{
                          paddingBottom: "20px",
                          maxWidth: "100%",
                          height: "auto",
                        }}
                      />
                      <h1 className="month">
                        6<br />
                        Days
                      </h1>
                      <h4 className="rupee">₹</h4>
                      <h1 className="offerPrice">{sixDaysPlans.listedPrice}</h1>
                      <h1 className="planName">{sixDaysPlans.title}</h1>
                      <h4 className="oldPrice">₹ {sixDaysPlans.amount}</h4>
                    </Col>
                  )}
                </Row>
              </Container>
            </>
          )}
        </>
      ) : (
        <Container style={{ padding: "20px", minHeight: "55rem" }}>
          <Container>
            <Row className="justify-content-center">
              <Col xs={12} className="text-center text-white">
                <h1>Subscription Packages</h1>
              </Col>
            </Row>
          </Container>
          <Row style={{ padding: "20px" }}>
            <Col
              xs={12}
              md={6}
              className={`d-flex flex-column align-items-center mb-4 ${
                isHoveredGold ? "zoomed-card" : ""
              }`}
              onMouseEnter={() => setIsHoveredGold(true)}
              onMouseLeave={() => setIsHoveredGold(false)}
              onClick={() => pushToLogin()}
            >
              <Image
                src="./subscrib.svg"
                style={{
                  paddingBottom: "20px",
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
              <h1 className="month">
                12
                <br />
                Months
              </h1>
              <h4 className="rupee">₹</h4>
              <h1 className="offerPrice">99</h1>
              <h1 className="planName">GOLD</h1>
              <h4 className="oldPrice">₹ 300</h4>
            </Col>

            <Col
              xs={12}
              md={6}
              className={`d-flex flex-column align-items-center mb-4 ${
                isHoveredSilver ? "zoomed-card" : ""
              }`}
              onMouseEnter={() => setIsHoveredSilver(true)}
              onMouseLeave={() => setIsHoveredSilver(false)}
              onClick={() => pushToLogin()}
            >
              <Image
                src="./subscrib.svg"
                style={{
                  paddingBottom: "20px",
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
              <h1 className="month">
                3<br />
                Months
              </h1>
              <h4 className="rupee">₹</h4>
              <h1 className="offerPrice">72</h1>
              <h1 className="planName">SILVER</h1>
              <h4 className="oldPrice">₹ 200</h4>
            </Col>
          </Row>
          <Row>
            <Col
              xs={12}
              md={6}
              className={`d-flex flex-column align-items-center mb-4 ${
                isHoveredBasic ? "zoomed-card" : ""
              }`}
              onMouseEnter={() => setIsHoveredBasic(true)}
              onMouseLeave={() => setIsHoveredBasic(false)}
              onClick={() => pushToLogin()}
            >
              <Image
                src="./subscrib.svg"
                style={{
                  paddingBottom: "20px",
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
              <h1 className="month">
                1<br />
                Month
              </h1>
              <h4 className="rupee">₹</h4>
              <h1 className="offerPrice">45</h1>
              <h1 className="planName">BASIC</h1>
              <h4 className="oldPrice">₹ 125</h4>
            </Col>

            <Col
              xs={12}
              md={6}
              className={`d-flex flex-column align-items-center mb-4 ${
                isHovered ? "zoomed-card" : ""
              }`}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={() => pushToLogin()}
            >
              <Image
                src="./subscrib.svg"
                style={{
                  paddingBottom: "20px",
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
              <h1 className="month">
                6<br />
                Days
              </h1>
              <h4 className="rupee">₹</h4>
              <h1 className="offerPrice">15</h1>
              <h1 className="planName">SIX DAYS</h1>
              <h4 className="oldPrice">₹ 87</h4>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default Subscribe;

// <div className="container">
//   <div className="row row-grid justify-content-center" style={{ minHeight: '690px' }}>
//     <div className="col-lg-12 packagesBox">
//       <div className="container">
//         <div className="row justify-content-center">
//           <div className="col-md-12 pageTtileBox">
//             <h1>Subscription Packages</h1>
//           </div>
//         </div>
//         <div className="row">
//           <div className="col-md-6">
//             <div className="info info-vertical bg-default packageinfoBox innerpack-0">
//               <div className="row innerPackageBox">
//                 <div className="col-sm-4 rightColBox" style={{ backgroundColor: 'transparent' }}>
//                   <h4 className="info-title"> 12 Months </h4>
//                 </div>
//                 <div className="col-sm-8 leftColBox" style={{ backgroundColor: 'transparent' }}>
//                   <div className="text-center">
//                     <button className="btn btn-info packageBtnBox" type="button"> GOLD </button>
//                   </div>
//                   <h1 className="price">
//                     <span className="deletedPrice">
//                       <del><small>₹</small> 300</del>
//                     </span>
//                     <span className="actualPrice">
//                       <small className="currency">₹</small> 99
//                     </span>
//                   </h1>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="col-md-6">
//             <div className="info info-vertical bg-default packageinfoBox innerpack-1">
//               <div className="row innerPackageBox">
//                 <div className="col-sm-4 rightColBox" style={{ backgroundColor: 'transparent' }}>
//                   <h4 className="info-title"> 3 Months </h4>
//                 </div>
//                 <div className="col-sm-8 leftColBox" style={{ backgroundColor: 'transparent' }}>
//                   <div className="text-center">
//                     <button className="btn btn-info packageBtnBox" type="button"> SILVER </button>
//                   </div>
//                   <h1 className="price">
//                     <span className="deletedPrice">
//                       <del><small>₹</small> 200</del>
//                     </span>
//                     <span className="actualPrice">
//                       <small className="currency">₹</small> 72
//                     </span>
//                   </h1>
//                 </div>
//               </div>
//             </div>
//           </div>
//           {/* Add more package columns similarly */}
//         </div>
//         <div className="row">
//           <div className="col-md-6">
//             <div className="info info-vertical bg-default packageinfoBox innerpack-0">
//               <div className="row innerPackageBox">
//                 <div className="col-sm-4 rightColBox" style={{ backgroundColor: 'transparent' }}>
//                   <h4 className="info-title"> 12 Months </h4>
//                 </div>
//                 <div className="col-sm-8 leftColBox" style={{ backgroundColor: 'transparent' }}>
//                   <div className="text-center">
//                     <button className="btn btn-info packageBtnBox" type="button"> GOLD </button>
//                   </div>
//                   <h1 className="price">
//                     <span className="deletedPrice">
//                       <del><small>₹</small> 300</del>
//                     </span>
//                     <span className="actualPrice">
//                       <small className="currency">₹</small> 99
//                     </span>
//                   </h1>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="col-md-6">
//             <div className="info info-vertical bg-default packageinfoBox innerpack-1">
//               <div className="row innerPackageBox">
//                 <div className="col-sm-4 rightColBox" style={{ backgroundColor: 'transparent' }}>
//                   <h4 className="info-title"> 3 Months </h4>
//                 </div>
//                 <div className="col-sm-8 leftColBox" style={{ backgroundColor: 'transparent' }}>
//                   <div className="text-center">
//                     <button className="btn btn-info packageBtnBox" type="button"> SILVER </button>
//                   </div>
//                   <h1 className="price">
//                     <span className="deletedPrice">
//                       <del><small>₹</small> 200</del>
//                     </span>
//                     <span className="actualPrice">
//                       <small className="currency">₹</small> 72
//                     </span>
//                   </h1>
//                 </div>
//               </div>
//             </div>
//           </div>
//           {/* Add more package columns similarly */}
//         </div>
//       </div>
//     </div>
//   </div>
// </div>

import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import "./Login.css"; // You can define your custom styles for the Login component
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { FaFacebook, FaGoogle, FaEye, FaEyeSlash } from "react-icons/fa";
import CustomInput from "../FooterPages/CustomInput";
import { useUser } from "../../UserContext";
import instance from "../common/Api";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { Toast } from "react-bootstrap";
import {
  FaKeycdn,
  FaLock,
  FaMobile,
  FaUser,
  FaUserAstronaut,
} from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
const LoginForm = (props) => {
  // const history = useHistory();
  const navigate = useNavigate();
  const [showOTPView, setShowOTPView] = useState(false);
  const [resetOTP, setResetOTP] = useState(null);
  const [newOTP, setNewOTP] = useState("");
  const [focused, setFocused] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [isForgotPass, setIsForgotPass] = useState(false);
  const [regMobile, setRegMobile] = useState("");
  const [regPassword, setRegPassword] = useState("");
  const [regfullName, setRegfullName] = useState("");
  const [showOtpFieldReset, setShowOtpFieldReset] = useState(false);

  const [value, setValue] = useState("");
  const [username, setUsername] = useState("");

  const [passwordError, setPasswordError] = useState("");

  const { setUserData, updateUser } = useUser();

  const [userSocial, setUserSocial] = useState([]);
  const [profileSocial, setProfileSocial] = useState([]);

  function isMobileDevice() {
    return window.matchMedia("(max-width: 1000px)").matches;
  }
  const [isMobile, setIsMobile] = useState(isMobileDevice());

  useEffect(() => {
    if (userSocial) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${userSocial.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${userSocial.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          console.warn(res.data);
          console.warn(res.data.name);
          console.warn(res.data.picture);
          console.warn(res.data.email);
          //
          handleUpdateGoogleUser(
            res.data.name,
            res.data.email,
            res.data.picture
          );
          // setProfileSocial(res.data);
          // localStorage.setItem("BebuAppUser", JSON.stringify(res.data));
          // localStorage.setItem("SocialLogin", true);
          // window.location.href = "/";
        })
        .catch((err) => console.log(err));
    }

    function handleResize() {
      setIsMobile(isMobileDevice());
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [userSocial]);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    if (value === "") {
      setFocused(false);
    }
  };

  const loginGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => setUserSocial(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  const logOutGoogle = () => {
    googleLogout();
    setProfileSocial(null);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const [showToast, setShowToast] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [resetmobileNumber, setResetmobileNumber] = useState("");
  const [toastMsg, setToastMsg] = useState("Please Try Again!");

  // const toggleToast = () => setShowToast(!showToast);
  // const [issueDescription, setIssueDescription] = useState("");

  // const responseMessage = (response) => {
  //   console.log(response);
  // };
  // const errorMessage = (error) => {
  //   console.log(error);
  // };

  const handleSubmit = () => {
    if (mobile.length === 10 && password !== "") {
      // Submit the form or do something with the data
      console.log("Mobile:", mobile);
      console.log("Password:", password);
    } else {
      alert("Please enter a valid mobile number and password.");
    }
  };

  const handleUpdateGoogleUser = (name, email, picture) => {
    instance
      .post(
        `/registerManual?device_type=web&authServiceProvider=GOOGLE&fullName=${name}&email=${email}&platform=WEB&login_by=google&picture=${picture}`
      )
      .then((response) => {
        // setUserData(null);
        const data = response.data;
        console.log("Response:registerManual", response.data);
        // updateUser(data);
        localStorage.setItem("BebuAppUser", JSON.stringify(data));
        window.location.href = "/";
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        // setLoading(false); // Set loading to false when fetch is complete
      });
  };

  const ValidateUserMobile1 = async () => {
    setMobile(regMobile);
    setPassword(regPassword);
    if (regMobile.length === 10 && regPassword !== "" && regfullName !== "") {
      console.log("LoginHandle:", regMobile, mobile, regPassword, password);
      instance
        .post(`/otp/sendRegisterOTP?mobileNumber=${regMobile}`)
        .then((response) => {
          // setUserData(null);
          const data = response.data;
          if (data.success) {
            console.log("ValidateUserMobile1", data);
            ManualRegister3();
            // setNewOTP(data.otptext);
            // setShowOTPView(true);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          // setLoading(false); // Set loading to false when fetch is complete
        });
    } else {
      alert("Please enter a valid mobile number");
    }
  };
  // "error_messages": "SQLSTATE[23000]: Integrity constraint violation: 1048 Column 'name' cannot be null (SQL: insert into `sub_profiles` (`user_id`, `name`, `picture`, `status`, `updated_at`, `created_at`) values (5837, ?, https:\/\/adminpanelconnect.bebu.app\/placeholder.png, 1, 2024-04-09 08:57:18, 2024-04-09 08:57:18))",

  const VerifyNewOTPHandle2 = async () => {
    instance
      .post(`/otp/validate?mobileNumber=${regMobile}&otpText=${newOTP}`)
      .then((response) => {
        const data = response.data;
        console.log(data, "otpvalidate");
        if (data.success) {
          // ManualRegister3();
        } else {
          alert(data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        // setLoading(false); // Set loading to false when fetch is complete
      });
    // https://adminpanelconnect.bebu.app/userApi/otp/validate?mobileNumber=5555555555&otpText=599070
  };
  ////`/registerManual?device_type=web&contact=${regMobile}&fullName=${regfullName}&password=${regPassword}&platform=WEB`
  const ManualRegister3 = async () => {
    setMobile(regMobile);
    setPassword(regPassword);
    if (regMobile.length === 10 && regPassword !== "") {
      instance
        .post(
          `/registerManual?device_type=web&contact=${regMobile}&fullName=${regfullName}&password=${regPassword}&platform=WEB&username=${regfullName}&login_by=manual`
        )
        .then((response) => {
          const data = response.data;
          if (data.success) {
            console.log(
              "/registerManual?:",
              response.data,
              "MOBILE::",
              mobile,
              "PASSWORD:::",
              password
            );
            // window.location.href = "/login";
            // LoginHandle();
            // LoginHandle();
          }
        })
        .catch((error) => {
          console.error("Error fetching registerManual data:", error);
        })
        .finally(() => {});
    } else {
      alert("Please enter a valid mobile number and password.");
    }
  };

  const handleForgotPassCheck = async () => {
    if (resetmobileNumber.length === 10) {
      setShowOtpFieldReset(true);
    } else {
      alert("Please enter a valid mobile number");
    }
  };

  const ValidateUserIfPresent = async () => {
    if (mobile.length === 10 && password !== "") {
      console.log("ValidateUserIfPresent:", mobile, password);
      instance
        .post(`/otp/sendRegisterOTP?mobileNumber=${mobile}&ip=${props.ip}`)
        .then((response) => {
          // setUserData(null);
          const data = response.data;
          if (data.success) {
            console.log("ValidateUserMobile1", data);
            // setNewOTP(data.otptext);
            // setShowOTPView(true);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          // setLoading(false); // Set loading to false when fetch is complete
        });
    } else {
      alert("Please enter a valid mobile number");
    }
  };

  const LoginHandle = async () => {
    if (mobile.length === 10 && password !== "") {
      console.log("LoginHandle:", mobile, password);
      instance
        .post(
          `/profileUser?id=null&token=TokenID&contact=${mobile}&password=${password}&prev_regID=null&reg_Mobile=${mobile}&ip=${props.ip}`
        )
        .then((response) => {
          // setUserData(null);
          if (response.data.success) {
            const data = response.data[0];
            console.log("Response:", response.data[0]);
            updateUser(data);
            localStorage.setItem("BebuAppUser", JSON.stringify(data));
            window.location.href = "/";
          } else {
            alert(
              `${response.data["error_messages "]},You may have entered wrong mobile or password.`
            );

            // toggleToast();
            // alert(response.data["error_messages "], "Please Try ");
            // ValidateUserIfPresent();
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          // setLoading(false); // Set loading to false when fetch is complete
        });
    } else {
      alert("Please enter a valid mobile number and password.");
    }
  };

  const isFormValid = () => {
    return username.trim() !== "" && password.trim() !== "";
  };

  const handleLogin = () => {
    if (!isFormValid()) {
      if (username.trim() === "") {
        setPasswordError("Username is required");
      }
      if (password.trim() === "") {
        setPasswordError("Password is required");
      }
      return;
    }
    // Proceed with login logic
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log("Mobile:", mobileNumber);
  //   console.log("Pass:", password);
  // };

  const handlePassForgot = () => {
    navigate("/forgot-password");
  };
  return (
    <div style={{ paddingTop: "3rem" }}>
      <h1 style={{ color: "white", marginLeft: isMobile ? "9%" : "19%" }}>
        {" "}
        {isLogin ? "Login" : "Register"}
      </h1>
      <div className="centered-container">
        <Container className="custom-container">
          <Row></Row>

          <Row className="custom-row">
            {isLogin ? (
              <Col
                xs={12}
                md={6}
                className="custom-col-login border-md-end order-md-1 order-2 no-border mt-3 mt-md-0"
              >
                <Form>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FaMobile
                      style={{
                        marginRight: "10px",
                        marginLeft: "-22px",
                        color: "white",
                        marginBottom: "75px",
                      }}
                    />
                    <div style={{ flexGrow: 1 }}>
                      {" "}
                      <CustomInput
                        label="Mobile Number"
                        placeholder="Enter your mobile number"
                        error="Please enter a 10-digit mobile number"
                        type="number"
                        onValueChange={(value) => setMobile(value)}
                      />
                      <p className="custom-muted">
                        Only Indian Mobile Numbers are allowed, others may use
                        Social Login
                      </p>
                    </div>
                  </div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FaLock
                      style={{
                        marginRight: "10px",
                        marginLeft: "-22px",
                        color: "white",
                        marginBottom: "25px",
                      }}
                    />
                    <div style={{ flexGrow: 1 }}>
                      {" "}
                      <CustomInput
                        label="Password"
                        placeholder="Enter your password"
                        error="Password field cannot be empty"
                        type="password"
                        onValueChange={(value) => setPassword(value)}
                      />
                    </div>
                  </div>
                </Form>

                <div className="password-input-container"></div>
                <div className="custom-button-div-login">
                  <Button
                    style={{
                      backgroundColor: "#2c2c2e",
                      borderWidth: "0",
                      fontSize: "12px",
                    }}
                    className="w-100 mb-3 text-white"
                    onClick={handlePassForgot}
                    // disabled={!isFormValid()}
                  >
                    Forgot Password?
                  </Button>
                </div>
                <Row className="mt-3 mt-md-0">
                  <Col xs={12} md={6} className="mb-3 mb-md-0 pr-md-1">
                    <Button
                      style={{ backgroundColor: "#58b7aa", borderWidth: "0" }}
                      className="w-100 text-black"
                      // disabled={}
                      onClick={() => LoginHandle()}
                    >
                      Login
                    </Button>
                  </Col>
                  <Col xs={12} md={6} className="pl-md-1">
                    <Button
                      variant="secondary"
                      className="w-100 text-black"
                      onClick={() => setIsLogin(false)}
                    >
                      Register Now
                    </Button>
                  </Col>
                </Row>
              </Col>
            ) : (
              <Col
                xs={12}
                md={6}
                className="custom-col-login border-md-end order-md-1 order-2 no-border mt-3 mt-md-0"
              >
                <Form>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FaUser
                      style={{
                        marginRight: "10px",
                        marginLeft: "-22px",
                        color: "white",
                        marginBottom: "25px",
                      }}
                    />
                    <div style={{ flexGrow: 1 }}>
                      <CustomInput
                        label="Full Name"
                        placeholder="Enter Name"
                        error="full name field cannot be empty"
                        type="text"
                        onValueChange={(value) => setRegfullName(value)}
                      />
                    </div>
                  </div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FaMobile
                      style={{
                        marginRight: "10px",
                        marginLeft: "-22px",
                        color: "white",
                        marginBottom: "25px",
                      }}
                    />
                    <div style={{ flexGrow: 1 }}>
                      {" "}
                      <CustomInput
                        label="Mobile Number"
                        placeholder="Enter your mobile number"
                        error="Please enter a 10-digit mobile number"
                        type="number"
                        onValueChange={(value) => setRegMobile(value)}
                      />
                    </div>
                  </div>
                  <p
                    className="custom-muted"
                    style={{ fontSize: "10px", marginBottom: "1px" }}
                  >
                    Only Indian Mobile Numbers are allowed, others may use
                    Social Login
                  </p>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FaLock
                      style={{
                        marginRight: "10px",
                        marginLeft: "-22px",
                        color: "white",
                        marginBottom: "25px",
                      }}
                    />
                    <div style={{ flexGrow: 1 }}>
                      <CustomInput
                        label="Password"
                        placeholder="Enter your password"
                        error="Password field cannot be empty"
                        type="password"
                        onValueChange={(value) => setRegPassword(value)}
                      />
                    </div>
                  </div>

                  {showOTPView && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FaLock
                        style={{
                          marginRight: "10px",
                          marginLeft: "-22px",
                          color: "white",
                          marginBottom: "25px",
                        }}
                      />
                      <div style={{ flexGrow: 1 }}>
                        <CustomInput
                          label="OTP"
                          placeholder="Enter your OTP"
                          error="OTP field cannot be empty"
                          type="text"
                          onValueChange={(value) => setNewOTP(value)}
                        />
                      </div>
                    </div>
                  )}
                </Form>
                <Row className="mt-3 mt-md-0">
                  <Col xs={12} md={6} className="mb-3 mb-md-0 pr-md-1">
                    <Button
                      style={{ backgroundColor: "#58b7aa", borderWidth: "0" }}
                      className="w-100 text-white"
                      // disabled={}

                      onClick={() =>
                        showOTPView
                          ? VerifyNewOTPHandle2()
                          : ValidateUserMobile1()
                      }
                    >
                      {showOTPView ? "Verify" : "Register"}
                    </Button>
                  </Col>
                  <Col xs={12} md={6} className="pl-md-1">
                    <Button
                      style={{
                        backgroundColor: "#2c2c2e",
                        borderWidth: "0",
                        fontSize: "12px",
                      }}
                      className="w-100 mb-3 text-white"
                      // disabled={!isFormValid()}
                      onClick={() => setIsLogin(true)}
                    >
                      Already Have Account
                    </Button>
                  </Col>
                </Row>
              </Col>
            )}
            <Col xs={12} lg={3} className="order-md-2 order-1">
              <h5 className="custom-heading">Social Login</h5>
              <p className="custom-muted mt-1">
                Quickly sign in with your social network
              </p>
              <Button
                variant="light"
                className="w-100 mb-3"
                onClick={loginGoogle}
              >
                <FaGoogle className="mr-2 mb-1" /> Google
              </Button>
              <Button variant="primary" className="w-100">
                <FaFacebook className="mr-2 mb-1" /> Facebook
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default LoginForm;

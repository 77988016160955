import React, { useRef, useState } from "react";
import { BiPlayCircle } from "react-icons/bi";
import { Container, Button, Carousel, Spinner } from "react-bootstrap";
import BannerImage from "../common/BannerImage";
import { Link, useNavigate } from "react-router-dom";
import instance from "../common/Api";

const HomeBanner = (props) => {
  const { isMobile, banner } = props;
  const [index, setIndex] = useState(0);
  const [mediaData, setMediaData] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleSelect = (selectedIndex) => {
    console.log("selectedIndex;;;", selectedIndex);
    setIndex(selectedIndex);
  };
  const handleClick = (item) => {
    console.log("HomeBanner;;;", item);

    createVideoData(item.title, item.admin_video_id);
    // navigate("/media/", { state: { someData: item } });
  };
  const createVideoData = (title, id) => {
    // setLoading(true);
    instance
      .get(`/videoViewBySlug?titleYearSlug=${title}&videoid=${id}`)
      .then((response) => {
        setMediaData(response.data);
        console.log("response.data", response.data);
        console.log("Again DAta", mediaData);

        // setMediaData(response.data);
        // navigate("/play", { state: { someData: mediaData, type: "main" } });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        if (mediaData !== null) {
          isMobile
            ? navigate("/play", { state: { someData: mediaData } })
            : navigate("/media/", { state: { someData: mediaData } });
        }
        // setLoading(false);
        // Set loading to false when fetch is complete
      });
  };
  return (
    <div>
      {/* {loading && (
        <div className="loading-overlay">
          <Spinner animation="border" />
        </div>
      )} */}
      <Carousel
        activeIndex={index}
        onSelect={handleSelect}
        style={{
          paddingTop: "1rem",
          backgroundColor: "transparent", //"#141414",
          width: "80%",
          height: "80%",
          margin: "0 auto",
        }}
      >
        {banner.map((item, idx) => (
          <Carousel.Item key={idx} onClick={() => handleClick(item)}>
            <Link
              // to={"/media/"}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <BannerImage imageUrl={item} />
              <Carousel.Caption className="d-none d-lg-block">
                <div
                  style={{ position: "absolute", bottom: "50px", left: "0px" }}
                >
                  <Button
                    variant="info"
                    className="d-flex align-items-center"
                    style={{ borderRadius: "25px", width: "auto" }}
                  >
                    <span className="ms-2 fw-bold text-black">
                      {item.description}
                    </span>
                  </Button>
                </div>
                <div
                  style={{
                    position: "absolute",
                    bottom: "50px",
                    right: "10px",
                  }}
                >
                  <Button
                    variant="info"
                    className="d-flex align-items-center"
                    style={{ borderRadius: "25px" }}
                  >
                    <BiPlayCircle size={20} />
                    <span className="ms-2 fw-bold text-black">Click Here</span>
                  </Button>
                </div>
              </Carousel.Caption>
            </Link>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default HomeBanner;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { UserProvider } from "./UserContext";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));
//BebuApp: 394161949677-tn1ugouptl481rlva1r7otbvi89j43am.apps.googleusercontent.com
//k_wmBgzJlWoJ8mFjLF8bQmTy
root.render(
  <GoogleOAuthProvider clientId="14688835477-u5ijskgkc42m4gaf1aa5msgi2u3kkpva.apps.googleusercontent.com">
    <React.StrictMode>
      <UserProvider>
        <App />
      </UserProvider>
    </React.StrictMode>
  </GoogleOAuthProvider>
);

//register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
